<template>
  <div class="edit-feature-toggle-sidebar">
    <div
      style="
        display: flex;
        align-items: center;
        padding: 40px;
        padding-bottom: 0;
      "
    >
      <CRIcon
        view-box="0 0 24 24"
        :width="20"
        :height="20"
        color="red"
        style="flex-shrink: 0"
      >
        alert_outline
      </CRIcon>
      <p style="color: #e10055; margin: 0; padding-left: 10px">
        {{ action }} '{{ name }}' feature?
      </p>
    </div>
    <div style="display: flex; padding: 40px">
      <p>
        <b>Description:</b>
        {{ description }}
      </p>
    </div>
    <v-spacer />
    <v-btn
      id="edit-feature-toggle-button"
      class="edit-feature-toggle-button"
      color="primary"
      style="margin: 0; border-radius: 0"
      @click="onUpdateFeatureToggle"
    >
      {{ action }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    action() {
      return this.enabled ? 'Disable' : 'Enable'
    },
  },
  methods: {
    ...mapActions({
      closeDialog: 'app/closeDialog',
      updateFeatureToggle: 'featureToggles/updateFeatureToggle',
    }),
    async onUpdateFeatureToggle() {
      await this.updateFeatureToggle(this.id)
      EventBus.$emit('global-table-view-refresh')
      this.closeDialog()
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-feature-toggle-sidebar {
  height: 100%;
  width: 500px;
}

.edit-feature-toggle-button {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  color: white;
  bottom: 0;
  width: inherit;
  height: 71px;
  padding: 24px 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
</style>
